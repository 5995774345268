import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout.js";

export default function NotFoundPage()
{
    return (
        <Layout>
            404, this page doesn't exist.
        </Layout>
    );
}
